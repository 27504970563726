import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('carousel', (mediaCount) => ({
    mediaCount: mediaCount,
    down: false,
    lockedX: false,
    lockedY: false,
    startx: 0,
    starty: 0,
    current: 0,
    previous: 0,
    width: 0,
    captionHeight: 0,
    init () {
        this.$refs.caption_outer.classList.add('transition-all', 'duration-200', 'ease-in-out');
        this.$refs.caption.classList.add('transition-all', 'duration-200');
        this.width = this.$refs.main_image.clientWidth;
        if (this.width == 0) {
            setTimeout(this.init.bind(this), 100);
        } else {
            this.setCaption();
            this.figures = this.$refs.main_image.querySelectorAll('figure');
        }
    },
    enableTransition () {
        this.figures.forEach(figure => {
            figure.style.transition = 'transform 0.3s ease-out';
        });
    },
    disableTransition () {
        this.figures.forEach(figure => {
            figure.style.transition = 'none';
        });
    },
    setCaption () {
        let caption = this.$refs.caption.getElementsByTagName('figcaption')[0];
        window.requestAnimationFrame(() => {
            caption.innerHTML = this.$refs.main_image.querySelector('figure.current')
                .dataset.caption;
            window.requestAnimationFrame(() => {
                this.captionHeight = this.$refs.caption.offsetHeight;
                this.$refs.caption_outer.style.height = `${this.captionHeight}px`;
                setTimeout(() => {
                    this.$refs.caption.style.opacity = 1;
                }, 200);
            })
        });
    },
    clamp (val, min = 0, max = this.mediaCount - 1) {
        return Math.min(Math.max(val, min), max);
    },
    setCurrent (val) {
        val = this.clamp(val);
        this.enableTransition();
        window.requestAnimationFrame(() => {
            if (this.previous != val) {
                this.captionHeight = this.$refs.caption_outer.offsetHeight;
                this.$refs.caption_outer.style.height = `${this.captionHeight}px`;
                this.$refs.caption.style.opacity = 0;
                setTimeout(() => {
                    this.setCaption();
                }, 200);
            }
            this.current = val;
            this.previous = this.current;
            setTimeout(() => {
                this.disableTransition();
            }, 350);
        });
    },
    onDown (evt) {
        if (evt instanceof TouchEvent) evt = evt.touches[0];
        this.startx = evt.clientX;
        this.starty = evt.clientY;
        this.down = true;
    },
    onMouseMove (evt) {
        if (this.down) {
            let min = this.previous > 0 ? this.previous - 1 : 0;
            let max = this.previous < this.mediaCount - 1 ? this.previous + 1 : this.mediaCount - 1;
            this.current = this.clamp(this.previous + (this.startx - evt.clientX) / this.width, min, max);
        }
    },
    onTouchMove (evt) {
        if (this.down) {
            let dX = Math.abs(this.startx - evt.touches[0].clientX);
            let dY = Math.abs(this.starty - evt.touches[0].clientY);
            if (!this.lockedX && !this.lockedY && (dX > 10 || dY > 10)) {
                this.lockedX = dX >= dY;
                this.lockedY = !this.lockedX;
            } else if (this.lockedX) {
                evt.preventDefault();
                let min = this.previous > 0 ? this.previous - 1 : 0;
                let max = this.previous < this.mediaCount - 1 ? this.previous + 1 : this.mediaCount - 1;
                this.current = this.clamp(this.previous + (this.startx - evt.touches[0].clientX) / this.width, min, max);
            }
        }
    },
    onUp (evt) {
        this.down = false;
        this.lockedX = false;
        this.lockedY = false;
        let diff = this.current - this.previous;
        if (diff > 0.2) {
            this.setCurrent(Math.ceil(this.current));
        } else if (diff < -0.2) {
            this.setCurrent(Math.floor(this.current));
        } else {
            this.setCurrent(Math.round(this.current));
        }
    },
    prev () {
        this.setCurrent(this.current - 1);
    },
    next () {
        this.setCurrent(this.current + 1);
    }
}));

Alpine.magic('carouselSlideStyle', () => {
    return (current, index) => {
        const q = index - current;
        const k = -1 < q && q <= 0 ? 1 - Math.abs(q) : 0 < q && q < 1 ? (1 - q) % 1 : 0;
        const p = (current * -100) + (0 <= q && q <= 1 ? 0 : (q - (q > 0)) * -50);
        const s = 0.50 + (-1 < q && q < 1 ? k * 0.50 : 0);
        return {
            transform: `translateX(${p}%) scale(${s})`,
            opacity: `${k*0.5 + 0.5}`
        }
    }
});
