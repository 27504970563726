// Danish month names
const monthNames = ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

// Dates for comparison
const now = new Date();
const aYearAgo = new Date();
const aDayAgo = new Date();
const fiftyNineMinutesAgo = new Date();
aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);
aDayAgo.setDate(aDayAgo.getDate() - 1);
fiftyNineMinutesAgo.setMinutes(fiftyNineMinutesAgo.getMinutes() - 59);

const relative_time = (date) => {
  // Less than 59 minutes ago
  if (date > fiftyNineMinutesAgo) {
    return `for ${Math.floor(((now - date)/1000)/60)} min. siden`;
  }
  // More than 59 minutes ago, but less than a day ago
  if (date > aDayAgo) {
    const dag_eller_gaar = (date.getDate() == now.getDate()) ? 'dag' : 'går';
    const hh = date.getHours().toString().padStart(2, '0');
    const mm = date.getMinutes().toString().padStart(2, '0');
    return `i ${dag_eller_gaar} kl. ${hh}:${mm}`;
  }
  // More than a day ago, but less than a year ago
  if (date > aYearAgo) {
    return `${date.getDate()}. ${monthNames[date.getMonth()]}`;
  }
  // More than a year ago
  return `${date.getDate()}. ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
};

const calc_relative_time = () => {
  Array.from(document.querySelectorAll('time.relative_time[datetime]')).forEach((element) => {
    element.innerText = relative_time(new Date(element.getAttribute('datetime')));
  });    
};

export default calc_relative_time;