import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('videoplayer', () => ({
    timer: null,
    init () {},
    left () {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(this.change.bind(this, 'leave'), 500);
    },
    entered () {
        if (this.timer) clearTimeout(this.timer);
        this.timer = setTimeout(this.change.bind(this, 'enter'), 500);
    },
    change (state) {
        console.log(state);
        console.log(this.$el.getElementsByTagName('media-controller')[0]);
    }
}))
