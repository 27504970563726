import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

import "media-chrome";
import "hls-video-element";
import calc_relative_time from "./frop/relative_time.js";

// import any alpine components, stores or data
import "./alpine/BackButton.js";
import "./alpine/Carousel.js";
import "./alpine/ImageComparison.js";
import "./alpine/LatestNews.js";
import "./alpine/LatestNewsPage.js";
import "./alpine/ReadingProgress.js";
import "./alpine/TextBox.js";
import "./alpine/TopMenu.js";
import "./alpine/VideoPlayer.js";
import "./alpine/VideoTable.js";
import "./alpine/UrlUpdater.js";

Livewire.start();

document.addEventListener("DOMContentLoaded", function (event) {
    calc_relative_time();
});
