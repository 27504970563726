import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('reading_progress', () => ({
    barStyle: { display: 'none', tranform: 'scaleX(0)' },
    target: null,
    tTop: 0,
    tHeight: 0,
    tBottom: 0,
    timer: null,
    time: 0,
    delay: 100,
    init () {
        this.target = document.querySelector('*[data-reading-progress-target="true"]');
        if (this.target) {
            this.barStyle.display = 'block';
            this.setStartValues();
            this.observer = new ResizeObserver(this.setStartValues.bind(this))
                .observe(this.target);
            let debouncedUpdateProgress = this.debounce(this.updateProgress.bind(this), this.delay);
            let throttledUpdateProgress = this.throttle(this.updateProgress.bind(this), this.delay);
            window.addEventListener('scroll', () => {
                debouncedUpdateProgress();
                throttledUpdateProgress();
            }, { passive: true });
        }
    },
    debounce (callback, delay) {
        let time;
        return (...args) => {
            clearTimeout(time);
            time = setTimeout(() => {
                callback(...args);
            }, delay);
        };
    },
    throttle (callback, delay) {
        let shouldWait = false;
        return (...args) => {
            if (shouldWait) return;
            callback(...args);
            shouldWait = true;
            setTimeout(() => {
                shouldWait = false;
            }, delay);
        };
    },
    setStartValues () {
        if (!this.target) return;
        this.tTop = this.target.offsetTop;
        this.offsetHeight = this.target.offsetHeight;
        this.tBottom = this.tTop + this.target.offsetHeight - window.innerHeight;
        this.tHeight = this.tBottom - this.tTop;
        this.updateProgress(this.target.offsetTop + this.target.offsetHeight);
    },
    updateProgress () {
        const scroll = window.pageYOffset;
        if (scroll >= this.tTop && scroll <= this.tBottom + 100) {
            this.barStyle.transform = `scaleX(${(scroll - this.tTop) / this.tHeight})`;
        } else {
            this.barStyle.transform = 'scaleX(0)';
        }
    },
}));
